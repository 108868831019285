import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createWidgetPluginExports } from '@wix/members-area-widget-plugin-lib/viewer';

import { Interaction, Origin } from '../../types';
import { createPublicAPIStore } from '../../services/public-api-store';
import {
  getInstanceFactory,
  getMetaData,
  getMetaSiteId,
  initProps,
  initServices,
  maybeOpenPrivatePagePreviewNotification,
  maybeOpenPrivateProfilePreviewNotification,
  setComponentSettings,
  setSitePresets,
} from '../../services/controller-utils';
import { initMonitoringService } from '../../services/monitoring';
import { createDataSyncService } from '../../services/data-sync-service';
import { createInitialDataFetchService } from '../../services/initial-data-fetch-service';
import { ProfileSubject } from '../../services/profile-subject';
import {
  createSettingsListener,
  registerCurrentUserListener,
  registerDataSyncListener,
  registerSettingsListeners,
  registerStoreChangeListener,
  registerWidgetPluginHostListeners,
} from '../../services/controller-listeners';
import createStore from '../../store';
import { membersTpaLoadedUou } from '@wix/bi-logger-members-app-uou/v2';
import { loadMembersCustomPages } from '@wix/bi-logger-members-app-users/v2';
import { getCommonBIEventProps } from '../../services/bi-event';

const noop = () => {};

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
  appData,
}) => {
  const { compId, appParams, wixCodeApi, platformAPIs } = controllerConfig;
  const { config } = controllerConfig;
  const { experiments, bi: biLogger } = flowAPI;
  const getInstance = getInstanceFactory(controllerConfig);
  const getPublicAPI = createPublicAPIStore(wixCodeApi.site.getPublicAPI);
  const metaData = getMetaData(getInstance);
  const services = initServices(compId, flowAPI, wixCodeApi);
  const monitoringService = initMonitoringService(flowAPI);
  const settingsListener = createSettingsListener(config.publicData);
  const dataSyncService = createDataSyncService(compId, platformAPIs.pubSub);
  const initialDataFetchService = createInitialDataFetchService({
    flowAPI,
    getInstance,
    services,
  });
  const profileSubject = appData?.profileSubject as ProfileSubject | undefined;

  const store = createStore({
    ...services,
    metaData,
    compId,
    flowAPI,
    wixCodeApi,
    platformAPIs,
    experiments,
    dataSyncService,
    monitoringService,
    initialDataFetchService,
    profileSubject,
    getPublicAPI,
    biLogger,
  });

  // https://wix.slack.com/archives/CL5TBPB8S/p1646123769866629
  // Remove viewer type condition after Bolt is deprecated
  if (flowAPI.controllerConfig.platformAPIs.bi?.viewerName === 'thunderbolt') {
    flowAPI.controllerConfig.setProps({ fitToContentHeight: true });
  }

  return {
    async pageReady() {
      const metaSiteId = getMetaSiteId(controllerConfig, metaData);

      const currentUserListenerOptions = {
        store,
        flowAPI,
        initialDataFetchService,
        widgetPluginService: services.widgetPluginService,
      };

      const initPropsOptions = {
        store,
        initialDataFetchService,
      };

      await monitoringService
        .toMonitored(Interaction.InitialDataLoad, initProps(initPropsOptions))
        .catch(noop);

      const renderWidget = async () => {
        setComponentSettings(store, controllerConfig.config.style.styleParams);
        const isWidgetPlugin = services.widgetPluginService.getIsWidgetPlugin();

        if (isWidgetPlugin) {
          registerWidgetPluginHostListeners(
            store,
            services.widgetPluginService,
            initialDataFetchService,
          );
        } else {
          registerCurrentUserListener(currentUserListenerOptions);
        }

        registerSettingsListeners({
          eventHandler: settingsListener,
          dataSyncService,
          store,
        });
        const storeHandlers = registerStoreChangeListener({
          metaSiteId,
          store,
          experiments,
          controllerConfig,
          flowAPI,
          dataSyncService,
        });
        registerDataSyncListener({
          dataSyncService,
          store,
          getPublicAPI,
          initialDataFetchService,
          flowAPI,
        });

        const commonBiData = getCommonBIEventProps(
          flowAPI,
          store.getState(),
          metaData,
        );

        if (flowAPI.environment.isEditor) {
          biLogger?.report(
            loadMembersCustomPages({
              instance_id: commonBiData.instance_id,
              biToken: commonBiData.biToken,
              pageId:
                flowAPI?.controllerConfig?.wixCodeApi?.site?.currentPage
                  ?.applicationId,
              pageName: Origin.Profile,
            }),
          );
        } else {
          biLogger?.report(
            membersTpaLoadedUou({
              ...commonBiData,
              origin: Origin.Profile,
              page_name: Origin.Profile,
              widget_name: Origin.Profile,
              widget_id: appParams.appDefinitionId,
              member_id: store.getState()?.users?.viewed?.uid,
              currentPageId:
                flowAPI?.controllerConfig?.wixCodeApi?.site?.currentPage
                  ?.applicationId,
            }),
          );
        }

        maybeOpenPrivateProfilePreviewNotification({
          store,
          flowAPI,
          experiments,
          handlers: storeHandlers,
        });

        maybeOpenPrivatePagePreviewNotification({
          store,
          flowAPI,
          experiments,
          handlers: storeHandlers,
        });
      };

      await monitoringService
        .toMonitored(Interaction.InitialWidgetRender, renderWidget())
        .catch(noop);
    },
    updateConfig(_, { style, publicData }) {
      settingsListener.notify(publicData.COMPONENT || {});

      if (style?.styleParams) {
        const { styleParams, ...sitePresets } = style;
        setComponentSettings(store, style.styleParams);
        setSitePresets(store, sitePresets);
      }
    },
    onBeforeUnLoad() {
      dataSyncService.unregisterListeners();

      if (profileSubject) {
        profileSubject.unregisterObservers();
      }
    },
    exports() {
      return createWidgetPluginExports(services.widgetPluginService);
    },
  };
};

export default createController;
